import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const SectionBanner = ({ data }) => {

    const { title, subtitle, bannerImage } = data
    const image = getImage(bannerImage)

    return (
        <div className="relative flex justify-center items-center">
            {bannerImage ? <GatsbyImage className="w-full" image={image} alt="Construction demonstration"  /> : <div className="bg-gray-darkest"></div> }
            <div className="absolute">
                <h4 className="uppercase font-bold font-rubik text-3xl md:text-7xl text-white text-center">{title}</h4>
                <h5 className="text-white font-rubik font-medium text-center">{ subtitle ? subtitle : null }</h5>
            </div>

        </div>
    );
}

export default SectionBanner