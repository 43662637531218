import React from "react"
import { Helmet } from "react-helmet"


const SEO = ({ title, description }) => {


  const metaTitle = title 
  const metaDescription = description 
  return (
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>{`${metaTitle}`}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:type" content="website" />
    </Helmet>
  )
}

export default SEO