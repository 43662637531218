import React from "react";

import Layout from "../components/Layout"
import { graphql } from 'gatsby'
import SectionBanner from '../components/SectionBanner'
import SEO from '../components/SEO'


import { GatsbyImage, getImage } from "gatsby-plugin-image"
const OurWork = ({ data }) => {

    const banner = data.contentfulPage.components[0]
    const images = data.contentfulPage.components[1].images
return (
    <Layout>
      <SEO title="VM Construction | Our Work" description="View our latest renovation projects by viewing our gallery. Our team is known for our friendly, efficient service. Our contractors and construction teams work quickly and efficiently to minimize the disruption to your daily routine." />
        <SectionBanner data={banner} />
            <div className="flex justify-center">
            <div className="flex flex-wrap lg:flex-row px-10 py-10 max-w-3xl xl:max-w-full">
            {images.map(imageItem => {
                        return (
                            <div className="w-full md:w-1/2 xl:w-1/4 py-4 px-4 my-1 shadow-xl">
                                <GatsbyImage image={getImage(imageItem)} />
                           </div>
                        )
                    })}
            </div>
            </div>

    </Layout>

);
        }



export const query = graphql`
query PortfolioPage {
    contentfulPage(contentful_id: {eq: "3CLwDl436yOjJcarOjCy1R"}) {
      components {
        ... on ContentfulPortfolioPhotos {
          images {
            gatsbyImageData( quality: 90)
          }
        }
        ... on ContentfulBanner {
          title
          subtitle
          bannerImage {
            gatsbyImageData(quality: 90, width: 1200)
          }
        }
      }
    }
  }
  
`

export default OurWork